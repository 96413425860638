@bgColor: #20264d;

body {
    font-family: 'Dosis', sans-serif;
    background-color: @bgColor;
    color: #ffffff;
    font-size: 12pt;
    font-weight: 400;
}

a {
    color: skyblue;
}

a:hover {
    color: #ffffff;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}

code {
    display: inline-block;
    vertical-align: middle;
    font-size: 80%;
    color: #000000;
    background-color: #ffffff;
    padding: 0.1em 0.2em 0.1em 0.2em;
    border-radius: 5px;
    margin: 0 0 0 0.5em;
}

#_search_results {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid Black;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
    background-color: #cbced4;
    color: #000000;
    overflow-y: scroll;
    z-index: 1000;
}

.x-table {
    width: 100%;
    margin: 0;
    padding: 0;
    
    &, & tr, & td {
        border-collapse: collapse;
        border-spacing: 0;
    }
    
    .x-divider hr {
        width: 100%;
        height: 0px;
        margin: 0;
        padding: 0;
        border-bottom: 1px dashed rgba(255,255,255,0.2);
    }
}

.x-stop-id {
    display: inline-block;
    vertical-align: middle;
    font-size: 50%;
    color: #000000;
    background-color: #ffffff;
    padding: 0.1em 0.2em 0.1em 0.2em;
    border-radius: 5px;
    margin: 0.3em 0 0 0.5em;
}

.x-time-char {
    display: inline-block;
    width: 0.55em;
    text-align: center;
}

.x-search-item {
    cursor: pointer;
    padding: 2px 4px;
    font-size: 10pt;
    
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
    
    &[data-selected="true"] {
        opacity: 0.2;
    }
}

.x-floating-select {
    display: block;
    height: 200px;
    overflow-y: scroll;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(1px);
}

.x-selected-stop {
    display: inline-flex;
    align-items: center;
    line-height: 120%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    font-size: 11pt;
    padding: 4px 8px;
    margin-right: 2px;
    margin-bottom: 2px;
    
    div:first-child {
        font-weight: bold;
    }
    
    cursor: pointer;
    user-select: none;
    
    div:not(:last-child) {
        margin-right: 4px;
    }
    
    .cross {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
        width: 10px;
        height: 12px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.x-stop-monitor {
    padding: 0 8px 8px 8px;
    border-radius: 8px;
    
    &[data-vehicle-mode="TRAM"] {
        background-color: #520f0f;
    }
    
    .leaflet-popup-content & {
        width: 250px;
        max-width: 90vw;
    }
    
}

.x-floating-edit-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: fixed;
    right: 4px;
    top: 4px;
    z-index: 1200;
    
    div {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        justify-items: center;
        padding: 8px;
        font-size: 16px;
        border-radius: 4px;
        line-height: 100%;
        cursor: pointer;
    
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            text-shadow: -1px -1px 0 black, 1px -1px 0 black, 1px 1px 0 black, -1px 1px 0 black;
        }
    }
}

.x-floating-map {
    position: fixed;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
    background-color: lighten(@bgColor, 10%);
    
    .x-floating-map-container {
        position: relative;
        width: 100%;
    
        .x-map {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            .leaflet-container {
            }
        }
    }
}

.x-gps-icon {
    z-index: 9999;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(0, 119, 255);
    border: 3px solid white;
    outline: 1px solid black;
    animation-name: breathe;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 50% 50%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

@keyframes breathe {
    0% {
        border-radius: 50%;
        background-color: rgb(0, 119, 255);
        opacity: 1;
    }
    50% {
        border-radius: 5px;
        background-color: rgb(72, 0, 255);
        opacity: 0.7;
    }
    100% {
        border-radius: 50%;
        background-color: rgb(0, 119, 255);
        opacity: 1;
    }
}

.x-bus-icon {
    z-index: 10000;
    .inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
        filter: brightness(130%);
        
        &[data-tram="true"] {
            filter: hue-rotate(110deg);
        }
        
        img {
            align-self: flex-start;
            width: 36px;
            grid-area: 1 / 1 / 2 / 2;
        }
        
        span {
            align-self: center;
            display: block;
            grid-area: 1 / 1 / 2 / 2;
            color: white;
            font-size: 12px;
            line-height: 100%;
            font-weight: bolder;
        }
    }
}

.x-map-stop-tooltip {
    text-align: center;
    font-size: 10px;
    
    b {
        font-size: 8px;
    }
}
