body {
  color: #fff;
  background-color: #20264d;
  font-family: Dosis, sans-serif;
  font-size: 12pt;
  font-weight: 400;
}

a {
  color: #87ceeb;
}

a:hover {
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

code {
  vertical-align: middle;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 0 0 .5em;
  padding: .1em .2em;
  font-size: 80%;
  display: inline-block;
}

#_search_results {
  color: #000;
  z-index: 1000;
  background-color: #cbced4;
  border: 1px solid #000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  box-shadow: 4px 4px 15px #00000080;
}

.x-table {
  width: 100%;
  margin: 0;
  padding: 0;
}

.x-table, .x-table tr, .x-table td {
  border-collapse: collapse;
  border-spacing: 0;
}

.x-table .x-divider hr {
  border-bottom: 1px dashed #fff3;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
}

.x-stop-id {
  vertical-align: middle;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  margin: .3em 0 0 .5em;
  padding: .1em .2em;
  font-size: 50%;
  display: inline-block;
}

.x-time-char {
  text-align: center;
  width: .55em;
  display: inline-block;
}

.x-search-item {
  cursor: pointer;
  padding: 2px 4px;
  font-size: 10pt;
}

.x-search-item:hover {
  background-color: #ffffff1a;
}

.x-search-item[data-selected="true"] {
  opacity: .2;
}

.x-floating-select {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: #fff3;
  border: 1px solid #ffffff1a;
  border-radius: 0 0 3px 3px;
  height: 200px;
  display: block;
  overflow-y: scroll;
}

.x-selected-stop {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff3;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 2px;
  margin-right: 2px;
  padding: 4px 8px;
  font-size: 11pt;
  line-height: 120%;
  display: inline-flex;
}

.x-selected-stop div:first-child {
  font-weight: bold;
}

.x-selected-stop div:not(:last-child) {
  margin-right: 4px;
}

.x-selected-stop .cross {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10px;
  height: 12px;
}

.x-stop-monitor {
  border-radius: 8px;
  padding: 0 8px 8px;
}

.x-stop-monitor[data-vehicle-mode="TRAM"] {
  background-color: #520f0f;
}

.leaflet-popup-content .x-stop-monitor {
  width: 250px;
  max-width: 90vw;
}

.x-floating-edit-button {
  z-index: 1200;
  grid-template-columns: 1fr 1fr;
  display: grid;
  position: fixed;
  top: 4px;
  right: 4px;
}

.x-floating-edit-button div {
  cursor: pointer;
  border-radius: 4px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  place-items: center;
  padding: 8px;
  font-size: 16px;
  line-height: 100%;
  display: grid;
}

.x-floating-edit-button div:hover {
  text-shadow: -1px -1px #000, 1px -1px #000, 1px 1px #000, -1px 1px #000;
  background-color: #fff3;
}

.x-floating-map {
  box-sizing: border-box;
  z-index: 1000;
  background-color: #2f3871;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.x-floating-map .x-floating-map-container {
  width: 100%;
  position: relative;
}

.x-floating-map .x-floating-map-container .x-map {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.x-gps-icon {
  z-index: 9999;
  transform-origin: 50%;
  background-color: #07f;
  border: 3px solid #fff;
  border-radius: 50%;
  outline: 1px solid #000;
  width: 16px;
  height: 16px;
  animation-name: breathe;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  box-shadow: 2px 2px 5px #00000080;
}

@keyframes breathe {
  0% {
    opacity: 1;
    background-color: #07f;
    border-radius: 50%;
  }

  50% {
    opacity: .7;
    background-color: #4800ff;
    border-radius: 5px;
  }

  100% {
    opacity: 1;
    background-color: #07f;
    border-radius: 50%;
  }
}

.x-bus-icon {
  z-index: 10000;
}

.x-bus-icon .inner {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  filter: brightness(130%);
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  place-content: center;
  place-items: center;
  display: grid;
}

.x-bus-icon .inner[data-tram="true"] {
  filter: hue-rotate(110deg);
}

.x-bus-icon .inner img {
  grid-area: 1 / 1 / 2 / 2;
  align-self: flex-start;
  width: 36px;
}

.x-bus-icon .inner span {
  color: #fff;
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
  font-size: 12px;
  font-weight: bolder;
  line-height: 100%;
  display: block;
}

.x-map-stop-tooltip {
  text-align: center;
  font-size: 10px;
}

.x-map-stop-tooltip b {
  font-size: 8px;
}

/*# sourceMappingURL=index.48e273e3.css.map */
